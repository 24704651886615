import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from "react-redux";
import AddGuest from "../../components/AddGuest/AddGuest";
import AddSupport from '../AddSupport/AddSupport';
import AddInterpreter from "../../components/AddInterpreter/AddInterpreter";
import TabAction from '../../store/actions/TabAction';
import { ADD_INTERPRETER_KEY, ADD_INTERPRETER_TITLE, ADD_GUEST_KEY, ADD_GUEST_TITLE, ADD_SUPPORT_TITLE, ADD_SUPPORT_KEY } from '../../constants/tab.constants';
import { STORE_NAMES } from "../../constants/store.constants";
import { applyMobileClass } from '../../helper/global.utilities';

const InvitationTabs = () => {
  const dispatch = useDispatch();
  const [currentTabKey, setCurrentTabKey] = useState(null);
  const { tabSettings } = useSelector(state => state?.[STORE_NAMES.TABS]);
  const { currentTabContext } = useSelector(state => state?.[STORE_NAMES.TABS]);
  const onTabClick = (tabKey) => {
    setCurrentTabKey(tabKey);
    dispatch(TabAction.setCurrentTabContext(tabKey));
  };
  const { deviceType } = useSelector((state) => state?.[STORE_NAMES.ZOOM_CLIENT]);
  useEffect(() => {
    const defaultTabKey = tabSettings?.[ADD_INTERPRETER_KEY]?.isDefault ? ADD_INTERPRETER_KEY : ADD_GUEST_KEY;
    setCurrentTabKey(defaultTabKey);
    dispatch(TabAction.setCurrentTabContext(defaultTabKey));
  }, [tabSettings]);

  return (
    <div className={`vve-tab-content-container ${applyMobileClass(deviceType, 'vve-landscape-container')} ${(currentTabContext === ADD_SUPPORT_KEY) ? 'vve-container-height' : ''}`}>
      {currentTabKey !== null &&
        <Tabs defaultActiveKey={currentTabKey} onSelect={onTabClick} className={`vve-add-tabs ${applyMobileClass(deviceType, 'vve-add-mobile-tabs vve-landscape-container')}`} fill="true">
          <Tab eventKey={ADD_INTERPRETER_KEY} title={ADD_INTERPRETER_TITLE} disabled={!tabSettings?.[ADD_INTERPRETER_KEY]?.enableTab} tabClassName="vve-add-tabitem" className="vve-tab-panel">
            {currentTabKey === ADD_INTERPRETER_KEY &&
              <AddInterpreter></AddInterpreter>
            }   
          </Tab>
          <Tab eventKey={ADD_GUEST_KEY} title={ADD_GUEST_TITLE} disabled={!tabSettings?.[ADD_GUEST_KEY]?.enableTab} tabClassName="vve-add-tabitem" className="vve-tab-panel">
            {currentTabKey === ADD_GUEST_KEY &&
              <AddGuest></AddGuest>
            }
          </Tab>
          {
            tabSettings?.[ADD_SUPPORT_KEY]?.supportTab && <Tab eventKey={ADD_SUPPORT_KEY} title={ADD_SUPPORT_TITLE} tabClassName="vve-add-tabitem" className="vve-tab-panel">
              {currentTabKey === ADD_SUPPORT_KEY &&
                <AddSupport></AddSupport>
              }
            </Tab>
          }
          
        </Tabs>
      }
    </div>
  );
};

export default InvitationTabs;