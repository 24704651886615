const ADD_INTERPRETER_KEY = 'add_interpreter';
const ADD_INTERPRETER_TITLE = 'Add Interpreter';
const ADD_GUEST_KEY  = 'add_guest';
const ADD_GUEST_TITLE  = 'Add Guest';
const ADD_SUPPORT_KEY  = 'support';
const ADD_SUPPORT_TITLE  = 'Support';
const SERVICE_NOW_KEY = "SNOW";
const SNOW_TEXT_CHECK = 1000;


const ONCALL_KEY = 'On_Call';
const ONCALL_TITLE = 'On Call';
const CLINICIAN_KEY = 'Clinician';
const CLINICIAN_TITLE = 'Clinician';
// used for tab settings only
const ADD_INTERPRETER_TAB = "Interpreter";
const ADD_GUEST_TAB = "Invite-Guest";
const ONCALL_CLINICIAN_TABS_REGION = ["MAS"];

/** 
 * Please make sure the feature texts and keys are same as directory config response. 
 * Continue adding and updating here as and when new menu is introduced  
 * */
const ONCALL_FEATURE = "oncall";
const DIRECTORY_FEATURE = "directory";
const REPORT_FEATURE = "reports";
const SUPPORT_FEATURE = "support";
const NEW_MENU_TABS = {
    [ONCALL_FEATURE]: { label: "ON CALL" },
    [DIRECTORY_FEATURE]: { label: "DIRECTORY" },
    [REPORT_FEATURE]: { label: "REPORTS" },
    [SUPPORT_FEATURE]: { label: "SUPPORT" }
}
const OLD_TAB_KEY_MAP_FOR_NEW_MENU = {
    [ONCALL_FEATURE]: ONCALL_KEY,
    [DIRECTORY_FEATURE]: CLINICIAN_KEY
};

export {
    ADD_INTERPRETER_KEY,
    ADD_INTERPRETER_TITLE,
    ADD_GUEST_KEY,
    ADD_GUEST_TITLE,
    ADD_INTERPRETER_TAB,
    ADD_GUEST_TAB,
    ONCALL_KEY,
    ONCALL_TITLE,
    CLINICIAN_KEY,
    CLINICIAN_TITLE,
    ONCALL_CLINICIAN_TABS_REGION,
    ADD_SUPPORT_KEY,
    ADD_SUPPORT_TITLE,
    SERVICE_NOW_KEY,
    SNOW_TEXT_CHECK,
    ONCALL_FEATURE,
    DIRECTORY_FEATURE,
    REPORT_FEATURE,
    SUPPORT_FEATURE,
    NEW_MENU_TABS,
    OLD_TAB_KEY_MAP_FOR_NEW_MENU
};
