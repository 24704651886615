import { APP_DEVICE_TYPE  } from "../constants/zoomApp.constants";
import { NEW_MENU_TABS, 
        ONCALL_FEATURE, 
        DIRECTORY_FEATURE, 
        REPORT_FEATURE, SUPPORT_FEATURE,
        ONCALL_CLINICIAN_TABS_REGION } from "../constants/tab.constants";

const _canDisplayMenuItem = ({ menuName, deviceType, directoryRegion }) => {
    let status = true;
    switch (menuName) {
        case ONCALL_FEATURE:
            status = ONCALL_CLINICIAN_TABS_REGION.includes(directoryRegion);
            break;
        case DIRECTORY_FEATURE:
            // no logic as of now
            break;
        case REPORT_FEATURE:
            status = (deviceType !== APP_DEVICE_TYPE.mobile)
            break;
        case SUPPORT_FEATURE:
            // no logic as of now
            break;
        default:
            status = false; // unknown menu item always false
            break;
    }
    return status;
};

const prepareMenuItems = ({ menuItems, deviceType, directoryRegion }) => {
    const enabledMenuItems = menuItems.filter(menuName => _canDisplayMenuItem({ menuName, deviceType, directoryRegion }));
    return enabledMenuItems.map((menuName, index) => ({
            id: menuName,
            title: NEW_MENU_TABS[menuName].label,
            selected: index===0
        })
    );
};

export {
    prepareMenuItems
};